
import { defineComponent, PropType } from 'vue'

import { message } from 'ant-design-vue'

import type { Annex } from '@/service/Api/types'

import { Display } from './config'

import Item from './Item.vue'

export default defineComponent({
  name: 'FileList',
  props: {
    dataSource: {
      type: Array as PropType<Annex[]>,
      default: () => []
    },
    showType: {
      type: Number as PropType<Display>,
      default: Display.EXHIBIT
    }
  },
  emits: ['update:dataSource'],
  setup(props, { emit }) {
    const fileRemove = (index: number): void => {
      const fileList = props.dataSource

      fileList.splice(index, 1)
      emit('update:dataSource', fileList)

      message.success('删除该附件成功')
    }

    return {
      fileRemove
    }
  },
  components: {
    Item
  }
})
