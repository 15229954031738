import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-67e9620e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "file-list" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Item = _resolveComponent("Item")!
  const _component_a_spin = _resolveComponent("a-spin")!

  return (_openBlock(), _createBlock(_component_a_spin, { spinning: false }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dataSource, (item, index) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "file-list-item",
            key: index
          }, [
            _createVNode(_component_Item, {
              item: item,
              "show-type": _ctx.showType,
              onRemove: _ctx.fileRemove,
              index: index
            }, null, 8, ["item", "show-type", "onRemove", "index"])
          ]))
        }), 128))
      ])
    ]),
    _: 1
  }))
}