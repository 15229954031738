
import { defineComponent, PropType } from 'vue'

import { useRouter } from 'vue-router'

export default defineComponent({
  name: 'PageHeader',
  props: {
    isMore: {
      type: Boolean as PropType<boolean>,
      default: false
    }
  },
  emits: ['more'],
  setup(props, { emit }) {
    const router = useRouter()

    const back = () => {
      router.back()
    }

    const moreCallback = () => {
      emit('more')
    }

    return {
      back,
      moreCallback
    }
  }
})
