
import { defineComponent, PropType, computed } from 'vue'

import { useRouter } from 'vue-router'

import { message } from 'ant-design-vue'

import { pressReg, ReplyType } from './config'

import { fileOpenPreview } from '@/utils'

import type { Annex } from '@/service/Api/types'

import { Display } from './config'

export default defineComponent({
  name: 'FileListItem',
  props: {
    item: {
      type: Object as PropType<Annex>
    },
    index: {
      type: Number as PropType<number>
    },
    showType: {
      type: Number as PropType<Display>,
      default: Display.EXHIBIT
    }
  },
  emits: ['remove'],
  setup(props, { emit }) {
    const router = useRouter()
    const suffix = computed(() => props.item?.extension || '')
    const url = computed(() => props.item?.path || '')
    const preview = () => {
      if (pressReg.test(suffix.value)) {
        return message.warning('该类型文件暂不支持在线预览')
      }
      fileOpenPreview(url.value)
    }

    const reply = (id: number | undefined, type: ReplyType): void => {
      if (id) {
        router.push({
          path: `/inbox/editor/${id}`,
          query: {
            type
          }
        })
      }
    }

    const removeItem = () => {
      emit('remove', props.index)
    }

    return {
      preview,
      reply,
      ReplyType,
      removeItem,
      Display
    }
  }
})
